<template>
</template>

<script>
  import { mapState } from "vuex";
  import { getJsonFile } from '@/utils/jsonFile';
  export default {
    props: {
      value: { type: String, default: null },
      page: { type: String, default: null },
      device: { type: String, default: null },
      title: { type: String, default: null },
      description: { type: String, default: null }
    },
    head() {
      let hasX      = Object.keys(this.$route.query).indexOf('x') >= 0;
      let data      = { meta: [], link: [], script: [] };
      let pageTitle = `${this.title} - ${this.WebSetting.WEBNAME}`;
      let pageHead  = [];
      if (hasX)
        pageHead = getJsonFile(process.env.DEF_webStyle, 'head_x');
      if (pageHead.length == 0)
        pageHead = getJsonFile(process.env.DEF_webStyle, 'head');

      if (this.title)
        data.title = this.title;

      if (Object.keys(pageHead).indexOf(this.page) >= 0) {
        let newDomain      = pageHead[this.page].domain || this.hostName;
        let newTitle       = pageHead[this.page].title || pageTitle;
        let newDescription = pageHead[this.page].description || `${pageTitle}${this.description}`;
        let noMobile       = pageHead[this.page].noMobile || false;
        let webSEOName     = Object.keys(this.WebSetting.WebSEO).indexOf('title') >= 0 ? this.WebSetting.WebSEO.title : '';

        newTitle = newTitle.replace('{WebName}', this.WebSetting.WEBNAME);
        newTitle = newTitle.replace('{WebSeoName}', webSEOName);

        if (Object.keys(pageHead[this.page]).indexOf('title') >= 0) {
          data.title = newTitle
        }

        if (Object.keys(pageHead[this.page]).indexOf('link') >= 0) {
          pageHead[this.page].link.forEach(element => {
            let tmpElement = JSON.parse(JSON.stringify(element));
            if (tmpElement.href === '') {
              let LinkHref = `${newDomain}${this.$route.path}`;
              let LinkX    = hasX ? '?x=new' : '';

              tmpElement.href = LinkHref + LinkX;
              if (tmpElement.rel === 'canonical' && noMobile)
                tmpElement.href = tmpElement.href.replace('/mobile', '');
            }
            data.link.push(tmpElement);
          });
        }

        if (Object.keys(pageHead[this.page]).indexOf('meta') >= 0) {
          pageHead[this.page].meta.forEach(element => {
            if (element.content == 'title') element.content = newTitle;
            if (element.content == 'description') element.content = newDescription;
            data.meta.push(element);
          });
        }

        if (Object.keys(pageHead[this.page]).indexOf('script') >= 0) {
          pageHead[this.page].script.forEach(element => {
            let regExp = /http/;
            if (Object.keys(element.json).indexOf('url') >= 0) {
              if (!regExp.test(element.json.url))
                element.json.url = newDomain + (this.IsPC ? '' : '/mobile') + element.json.url;
            }
            if (Object.keys(element.json).indexOf('itemListElement') >= 0) {
              element.json.itemListElement.forEach((e, i) => {
                if (Object.keys(e).indexOf('url') >= 0 && !regExp.test(e.url))
                  element.json.itemListElement[i].url = newDomain + (this.IsPC ? '' : '/mobile') + e.url;
                if (Object.keys(e).indexOf('item') >= 0 && !regExp.test(e.item))
                  element.json.itemListElement[i].item = newDomain + (this.IsPC ? '' : '/mobile') + e.item;
              });
            }
            data.script.push(element);
          });
        }

        if (Object.keys(pageHead[this.page]).indexOf(this.device) >= 0) {
          if (Object.keys(pageHead[this.page][this.device]).indexOf('link') >= 0) {
            pageHead[this.page][this.device].link.forEach(element => {
              let tmpElement = JSON.parse(JSON.stringify(element));
              if (tmpElement.href === '') {
                let LinkHref = `${newDomain}${this.$route.path}`;
                let LinkX    = hasX ? '?x=new' : '';

                tmpElement.href = LinkHref + LinkX;
                if (tmpElement.rel === 'canonical' && noMobile)
                  tmpElement.href = tmpElement.href.replace('/mobile', '');
              }
              data.link.push(tmpElement);
            });
          }

          if (Object.keys(pageHead[this.page][this.device]).indexOf('meta') >= 0) {
            pageHead[this.page][this.device].meta.forEach(element => {
              if (element.content == 'title') element.content = newTitle;
              if (element.content == 'description') element.content = newDescription;
              data.meta.push(element);
            });
          }

          if (Object.keys(pageHead[this.page][this.device]).indexOf('script') >= 0) {
            pageHead[this.page][this.device].script.forEach(element => {
              let regExp = /http/;
              if (Object.keys(element.json).indexOf('url') >= 0) {
                if (!regExp.test(element.json.url))
                  element.json.url = newDomain + (this.IsPC ? '' : '/mobile') + element.json.url;
              }
              if (Object.keys(element.json).indexOf('itemListElement') >= 0) {
                element.json.itemListElement.forEach((e, i) => {
                  if (Object.keys(e).indexOf('url') >= 0 && !regExp.test(e.url))
                    element.json.itemListElement[i].url = newDomain + (this.IsPC ? '' : '/mobile') + e.url;
                  if (Object.keys(e).indexOf('item') >= 0 && !regExp.test(e.item))
                    element.json.itemListElement[i].item = newDomain + (this.IsPC ? '' : '/mobile') + e.item;
                });
              }
              data.script.push(element);
            });
          }
        }
      }

      if (this.page == 'layouts' && Object.keys(this.WebSetting).indexOf('WebSEO') >= 0) {
        if (Object.keys(this.WebSetting.WebSEO).indexOf('title') >= 0) {
          data.title = this.WebSetting.WebSEO.title
          data.meta.push({ hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.WebSetting.WebSEO.title })
          data.meta.push({ hid: 'og:title', property: 'og:title', content: this.WebSetting.WebSEO.title })
          data.meta.push({ hid: 'og:site_name', property: 'og:site_name', content: this.WebSetting.WebSEO.title })
        }
        if (Object.keys(this.WebSetting.WebSEO).indexOf('description') >= 0) {
          data.meta.push({ hid: 'description', name: 'description', content: this.WebSetting.WebSEO.description })
          data.meta.push({ hid: 'og:description', property: 'og:description', content: this.WebSetting.WebSEO.description })
        }
        if (Object.keys(this.WebSetting.WebSEO).indexOf('keywords') >= 0) {
          data.meta.push({ hid: 'keywords', name: 'keywords', content: this.WebSetting.WebSEO.keywords })
        }
        if (Object.keys(this.WebSetting.WebSEO).indexOf('author') >= 0) {
          data.meta.push({ hid: 'author', name: 'author', content: this.WebSetting.WebSEO.author })
        }
        if (Object.keys(this.WebSetting.WebSEO).indexOf('copyright') >= 0) {
          data.meta.push({ hid: 'copyright', name: 'copyright', content: this.WebSetting.WebSEO.copyright })
        }
        if (Object.keys(this.WebSetting.WebSEO).indexOf('google_site_verification') >= 0) {
          data.meta.push({ hid: 'google-site-verification', name: 'google-site-verification', content: this.WebSetting.WebSEO.google_site_verification })
        }
      }

      if (this.page == 'index' && this.device == 'desktop' && Object.keys(this.WebSetting).indexOf('WebSEO') >= 0) {
        if (Object.keys(this.WebSetting.WebSEO).indexOf('script') >= 0 && this.WebSetting.WebSEO.script) {
          data.script.push({ "type": "application/ld+json", "json": this.WebSetting.WebSEO.script });
        }
      }

      if (this.page == 'egame' && hasX && this.title) {
        data.title = this.title;
        data.meta.push({ hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.title });
        data.meta.push({ hid: 'og:title', property: 'og:title', content: this.title });
        data.meta.push({ hid: 'og:site_name', property: 'og:site_name', content: this.title });
      }

      return data;
    },
    computed: {
      IsPC () {
        if (Object.keys(this.DeviceType).indexOf('type') < 0 || this.DeviceType.type != 'pc')
          return false
        else
          return true
      },
      ...mapState(['WebSetting', 'hostName', 'DeviceType']),
    }
  };
</script>
