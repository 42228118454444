<template>
  <div class="lang-wrapbox">

    <b-dropdown
      variant="link"
      toggle-class="text-decoration-none"
      class="lang-wrap"
      no-caret
      v-model="$i18n.locale"
    >
      <template #button-content>
        <i :class="`flag flag-${$i18n.locale}`" :data-name="$i18n.locale"></i>
      </template>
      <b-dropdown-item
        v-for="(item, i) in langs"
        v-show="$i18n.locale != item.lang"
        :key="`Lang${i}`"
        :value="item.lang"
        href="#"
        @click="setLang(item.lang)"
      >
        <i :class="`flag flag-${item.lang}`" :data-name="item.min"></i>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { setLocale } from '~/utils/locale';
export default {
  name: "vuei18n",
  data() {
    return {
      langs123: [
        // { lang: "zh-TW", min: "繁體中文" },
        { lang: "en-US", min: "ENGLISH" },
        //  { lang: "cn", min: "简体中文" },
        //  { lang: "vi", min: "Tiếng Việt" },
        //  { lang: "th", min: "ไทย" },
        { lang: "hi-IN", min: "भारत" },
        //  { lang: "id", min: "Indonesia" },
        //  { lang: "ms", min: "Malay" },
        //  { lang: "js", min: "日本語" },
      ],
      langs_ch: [
        { lang: "zh-TW", min: "繁體中文" },
        { lang: "en-US", min: "ENGLISH" },
      ],
      langs_en: [
        { lang: "en-US", min: "ENGLISH" },
        { lang: "hi-IN", min: "भारत" },
      ],
    };
  },
  methods: {
    setLang(lang) {
      setLocale(lang);
      this.$i18n.locale = lang;
    }
  },
  computed: {
    langs(){
      if (this.DEF_webStyle === 'tyc'|| this.DEF_webStyle === 'tycn'){
        return this.langs_ch;
      }else{
        return this.langs_en;
      }
    },
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
  }
};
</script>
